import ROLES from "../../Roles.json";

export default [
  {
    path: "/cash-mgnt",
    component: () =>
      import("../views/cash-management/CashManagementDefaultWrapper.vue"),
    meta: {
      title: "screens.cashManagement.Main.pageTitle",
    },
    children: [
      {
        path: "",
        name: "Cash Management Main Page",
        component: () =>
          import("../views/cash-management/CashManagementMainPage.vue"),
        meta: {
          title: "screens.cashManagement.Main.pageTitle",
          requiresRoles: [
            ROLES.AssistantStationManager,
            ROLES.SeniorAssistantStationManager,
            ROLES.StationManager,
            ROLES.SeniorStationManager,
            ROLES.ServiceOperationManager,
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
            ROLES.FSOAdministrativeOfficer,
            ROLES.FSOManager,
            ROLES.SystemAdmin,
          ],
        },
      },
      {
        path: "psc",
        component: () => import("../views/cash-management/PSC/PSCWrapper.vue"),
        meta: {
          title: "screens.cashManagement.PSC.Main.pageTitle",
        },
        children: [
          {
            path: "",
            name: "PSC Main Page",
            component: () =>
              import("../views/cash-management/PSC/PSCMainPage.vue"),
            meta: {
              title: "screens.cashManagement.PSC.Main.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
                ROLES.ServiceOperationManager,
                ROLES.ManagerStationOperation,
                ROLES.StationOperationManagement,
                ROLES.FSOAdministrativeOfficer,
                ROLES.FSOManager,
              ],
            },
          },
          {
            path: "preencoded-ticket",
            component: () => import("../views/DefaultWrapper.vue"),
            meta: {
              title:
                "screens.cashManagement.PSC.PreEncodedTicket.Main.pageTitle",
            },
            children: [
              {
                path: "",
                name: "Pre Encoded Ticket Main Page",
                component: () =>
                  import(
                    "../views/cash-management/PSC/PreEncodedTicket/MainPage.vue"
                  ),
                meta: {
                  title:
                    "screens.cashManagement.PSC.PreEncodedTicket.Main.pageTitle",
                  requiresRoles: [
                    ROLES.AssistantStationManager,
                    ROLES.SeniorAssistantStationManager,
                    ROLES.StationManager,
                    ROLES.SeniorStationManager,
                    ROLES.ServiceOperationManager,
                    ROLES.ManagerStationOperation,
                    ROLES.StationOperationManagement,
                    ROLES.FSOAdministrativeOfficer,
                    ROLES.FSOManager,
                  ],
                },
              },
              {
                path: "create-event",
                name: "Pre Encoded Ticket Create Event",
                component: () =>
                  import(
                    "../views/cash-management/PSC/PreEncodedTicket/CreateEvent.vue"
                  ),
                meta: {
                  title:
                    "screens.cashManagement.PSC.PreEncodedTicket.CreateEvent.pageTitle",
                  requiresRoles: [
                    ROLES.StationManager,
                    ROLES.SeniorStationManager,
                    ROLES.ServiceOperationManager,
                  ],
                },
              },
              {
                path: "create-ticket-log",
                name: "Pre Encoded Create Ticket",
                component: () =>
                  import(
                    "../views/cash-management/PSC/PreEncodedTicket/CreateTicket.vue"
                  ),
                meta: {
                  title:
                    "screens.cashManagement.PSC.PreEncodedTicket.CreateTicketLog.pageTitle",
                  requiresRoles: [
                    ROLES.StationManager,
                    ROLES.SeniorStationManager,
                    ROLES.ServiceOperationManager,
                  ],
                },
              },
              {
                path: "update-ticket-log/:id",
                name: "Pre Encoded Update Ticket",
                component: () =>
                  import(
                    "../views/cash-management/PSC/PreEncodedTicket/UpdateTicket.vue"
                  ),
                meta: {
                  title:
                    "screens.cashManagement.PSC.PreEncodedTicket.UpdateTicketLog.pageTitle",
                  requiresRoles: [
                    ROLES.StationManager,
                    ROLES.SeniorStationManager,
                    ROLES.ServiceOperationManager,
                  ],
                },
              },
              {
                path: "shift-end-summary",
                name: "PET Shift End Summary",
                component: () =>
                  import(
                    "../views/cash-management/PSC/PreEncodedTicket/PETShiftEndSummary.vue"
                  ),
                meta: {
                  title:
                    "screens.cashManagement.PSC.PreEncodedTicket.CreateShiftEnd.pageTitle",
                  requiresRoles: [
                    ROLES.StationManager,
                    ROLES.SeniorStationManager,
                  ],
                },
              },
            ],
          },
          {
            path: "psm-manual-record",
            component: () => import("../views/DefaultWrapper.vue"),
            meta: {
              title:
                "screens.cashManagement.PSC.PSMManualRecord.Main.pageTitle",
            },
            children: [
              {
                path: "",
                name: "PSM Manual Record Main Page",
                component: () =>
                  import(
                    "../views/cash-management/PSC/ManualRecord/MainPage.vue"
                  ),
                meta: {
                  title:
                    "screens.cashManagement.PSC.PSMManualRecord.Main.pageTitle",
                  requiresRoles: [
                    ROLES.AssistantStationManager,
                    ROLES.SeniorAssistantStationManager,
                    ROLES.StationManager,
                    ROLES.SeniorStationManager,
                    ROLES.ServiceOperationManager,
                    ROLES.ManagerStationOperation,
                    ROLES.StationOperationManagement,
                    ROLES.FSOAdministrativeOfficer,
                    ROLES.FSOManager,
                  ],
                },
              },
              {
                path: "create",
                name: "Create Manual Record",
                component: () =>
                  import(
                    "../views/cash-management/PSC/ManualRecord/CreateManualRecord.vue"
                  ),
                meta: {
                  title:
                    "screens.cashManagement.PSC.PSMManualRecord.Create.pageTitle",
                  requiresRoles: [
                    ROLES.AssistantStationManager,
                    ROLES.SeniorAssistantStationManager,
                    ROLES.StationManager,
                    ROLES.SeniorStationManager,
                    ROLES.FSOAdministrativeOfficer,
                    ROLES.FSOManager,
                  ],
                },
              },
              {
                path: "view/:transactionId",
                name: "View Manual Record",
                component: () =>
                  import(
                    "../views/cash-management/PSC/ManualRecord/ViewManualRecord.vue"
                  ),
                meta: {
                  title:
                    "screens.cashManagement.PSC.PSMManualRecord.View.pageTitle",
                  requiresRoles: [
                    ROLES.AssistantStationManager,
                    ROLES.SeniorAssistantStationManager,
                    ROLES.StationManager,
                    ROLES.SeniorStationManager,
                    ROLES.ServiceOperationManager,
                    ROLES.ManagerStationOperation,
                    ROLES.StationOperationManagement,
                    ROLES.FSOAdministrativeOfficer,
                    ROLES.FSOManager,
                  ],
                },
              },
              {
                path: "view-transaction/:transactionId",
                name: "View Manual Record Transaction",
                component: () =>
                  import(
                    "../views/cash-management/PSC/ManualRecord/ViewTransactionSnapshot.vue"
                  ),
                meta: {
                  title:
                    "screens.cashManagement.PSC.PSMManualRecord.ViewSnapshot.pageTitle",
                  requiresRoles: [
                    ROLES.AssistantStationManager,
                    ROLES.SeniorAssistantStationManager,
                    ROLES.StationManager,
                    ROLES.SeniorStationManager,
                    ROLES.ServiceOperationManager,
                    ROLES.ManagerStationOperation,
                    ROLES.StationOperationManagement,
                    ROLES.FSOAdministrativeOfficer,
                    ROLES.FSOManager,
                  ],
                },
              },
              {
                path: "edit/:transactionId",
                name: "Edit Manual Record",
                component: () =>
                  import(
                    "../views/cash-management/PSC/ManualRecord/EditManualRecord.vue"
                  ),
                meta: {
                  title:
                    "screens.cashManagement.PSC.PSMManualRecord.Update.pageTitle",
                  requiresRoles: [
                    ROLES.AssistantStationManager,
                    ROLES.SeniorAssistantStationManager,
                    ROLES.StationManager,
                    ROLES.SeniorStationManager,
                    ROLES.FSOAdministrativeOfficer,
                    ROLES.FSOManager,
                  ],
                },
              },
              {
                path: "shift-end-summary",
                name: "PSM Shift End Summary",
                component: () =>
                  import(
                    "../views/cash-management/PSC/ManualRecord/MRShiftEndSummary.vue"
                  ),
                meta: {
                  title:
                    "screens.cashManagement.PSC.PSMManualRecord.CreateShiftEnd.pageTitle",
                  requiresRoles: [
                    ROLES.StationManager,
                    ROLES.SeniorStationManager,
                    ROLES.AssistantStationManager,
                    ROLES.SeniorAssistantStationManager,
                    ROLES.ServiceOperationManager,
                    ROLES.ManagerStationOperation,
                    ROLES.StationOperationManagement,
                  ],
                },
              },
            ],
          },
          {
            path: "cash-float-elog",
            component: () => import("../views/DefaultWrapper.vue"),
            meta: {
              title: "screens.cashManagement.PSC.CashFloat.Main.pageTitle",
            },
            children: [
              {
                path: "",
                name: "Cash Float e-Log",
                component: () =>
                  import(
                    "../views/cash-management/PSC/CashFloatELog/MainPage.vue"
                  ),
                meta: {
                  title: "screens.cashManagement.PSC.CashFloat.Main.pageTitle",
                  requiresRoles: [
                    ROLES.AssistantStationManager,
                    ROLES.SeniorAssistantStationManager,
                    ROLES.StationManager,
                    ROLES.SeniorStationManager,
                    ROLES.ServiceOperationManager,
                    ROLES.ManagerStationOperation,
                    ROLES.StationOperationManagement,
                    ROLES.FSOAdministrativeOfficer,
                    ROLES.FSOManager,
                  ],
                },
              },
              {
                path: "move-cash-bag",
                name: "Cash Float e-Log Move Cash Bag",
                component: () =>
                  import(
                    "../views/cash-management/PSC/CashFloatELog/MoveCashBagMainPage.vue"
                  ),
                meta: {
                  title:
                    "screens.cashManagement.PSC.CashFloat.MoveCashBag.pageTitle",
                  requiresRoles: [
                    ROLES.StationManager,
                    ROLES.SeniorStationManager,
                    ROLES.ServiceOperationManager,
                  ],
                },
              },
              {
                path: "view-cash-bag/:bagNo",
                name: "Cash Float e-Log View Cash Bag",
                component: () =>
                  import(
                    "../views/cash-management/PSC/CashFloatELog/ViewCashBag.vue"
                  ),
                meta: {
                  title:
                    "screens.cashManagement.PSC.CashFloat.ViewCashBag.pageTitle",
                  requiresRoles: [
                    ROLES.AssistantStationManager,
                    ROLES.SeniorAssistantStationManager,
                    ROLES.StationManager,
                    ROLES.SeniorStationManager,
                    ROLES.ServiceOperationManager,
                    ROLES.ManagerStationOperation,
                    ROLES.StationOperationManagement,
                    ROLES.FSOAdministrativeOfficer,
                    ROLES.FSOManager,
                  ],
                },
              },
              {
                path: "shift-end-summary",
                name: "Cash Float e-Log Shift End Summary",
                component: () =>
                  import(
                    "../views/cash-management/PSC/CashFloatELog/CFShiftEndSummary.vue"
                  ),
                meta: {
                  title:
                    "screens.cashManagement.PSC.CashFloat.CreateShiftEnd.pageTitle",
                  requiresRoles: [
                    ROLES.StationManager,
                    ROLES.SeniorStationManager,
                  ],
                },
              },
            ],
          },
          {
            path: "discrepancy-report",
            component: () => import("../views/DefaultWrapper.vue"),
            meta: {
              title:
                "screens.cashManagement.PSC.DiscrepancyReport.CreateDiscrepancyReport.pageTitle",
            },
            children: [
              {
                path: "",
                name: "Create Discrepancy Report PSC",
                component: () =>
                  import(
                    "../views/cash-management/PSC/DiscrepancyReport/CreateDiscrepancyPSC.vue"
                  ),
                meta: {
                  title:
                    "screens.cashManagement.PSC.DiscrepancyReport.CreateDiscrepancyReport.pageTitle",
                  requiresRoles: [
                    ROLES.StationManager,
                    ROLES.SeniorStationManager,
                    ROLES.ServiceOperationManager,
                    ROLES.FSOAdministrativeOfficer,
                    ROLES.FSOManager,
                  ],
                },
              },
            ],
          },
        ],
      },
      {
        path: "afc",
        component: () => import("../views/DefaultWrapper.vue"),
        meta: {
          title: "screens.cashManagement.AFC.Main.pageTitle",
        },
        children: [
          {
            path: "",
            name: "AFC Main Page",
            component: () =>
              import("../views/cash-management/AFC/AFCMainPage.vue"),
            meta: {
              title: "screens.cashManagement.AFC.Main.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
                ROLES.ServiceOperationManager,
                ROLES.ManagerStationOperation,
                ROLES.StationOperationManagement,
                ROLES.FSOAdministrativeOfficer,
                ROLES.FSOManager,
              ],
            },
          },
          {
            path: "cashbag",
            component: () => import("../views/DefaultWrapper.vue"),
            meta: {
              title: "screens.cashManagement.AFC.Cashbag.Main.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
                ROLES.ServiceOperationManager,
                ROLES.ManagerStationOperation,
                ROLES.StationOperationManagement,
                ROLES.FSOAdministrativeOfficer,
                ROLES.FSOManager,
              ],
            },
            children: [
              {
                path: "",
                name: "Cashbag Main Page",
                component: () =>
                  import(
                    "../views/cash-management/AFC/Cashbag/CashbagMainPage.vue"
                  ),
              },
              {
                path: "movement",
                component: () => import("../views/DefaultWrapper.vue"),
                meta: {
                  title:
                    "screens.cashManagement.AFC.Cashbag.Movement.pageTitle",
                  requiresRoles: [
                    ROLES.StationManager,
                    ROLES.SeniorStationManager,
                    ROLES.ServiceOperationManager,
                  ],
                },
                children: [
                  {
                    path: "",
                    name: "Cashbag Movement",
                    component: () =>
                      import(
                        "../views/cash-management/AFC/Cashbag/CashbagMovement.vue"
                      ),
                  },
                ],
              },
            ],
          },
          {
            path: "cashbox",
            component: () => import("../views/DefaultWrapper.vue"),
            meta: {
              title: "screens.cashManagement.AFC.Cashbox.Main.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
                ROLES.ServiceOperationManager,
                ROLES.ManagerStationOperation,
                ROLES.StationOperationManagement,
                ROLES.FSOAdministrativeOfficer,
                ROLES.FSOManager,
              ],
            },
            children: [
              {
                path: "",
                name: "Cashbox Main Page",
                component: () =>
                  import(
                    "../views/cash-management/AFC/Cashbox/CashboxMainPage.vue"
                  ),
              },
              {
                path: "movement",
                name: "Cashbox Movement",
                component: () =>
                  import(
                    "../views/cash-management/AFC/Cashbox/CashboxMovement.vue"
                  ),
                meta: {
                  title:
                    "screens.cashManagement.AFC.Cashbox.Movement.pageTitle",
                  requiresRoles: [
                    ROLES.StationManager,
                    ROLES.SeniorStationManager,
                    ROLES.ServiceOperationManager,
                  ],
                },
              },
              {
                path: "new",
                name: "New Cashbox",
                component: () =>
                  import("../views/cash-management/AFC/Cashbox/CashboxNew.vue"),
                meta: {
                  title: "screens.cashManagement.AFC.Cashbox.New.pageTitle",
                  requiresRoles: [
                    ROLES.StationManager,
                    ROLES.SeniorStationManager,
                    ROLES.ServiceOperationManager,
                  ],
                },
              },
            ],
          },
          {
            path: "cointube",
            component: () => import("../views/DefaultWrapper.vue"),
            meta: {
              title: "screens.cashManagement.AFC.Cointube.Main.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
                ROLES.ServiceOperationManager,
                ROLES.ManagerStationOperation,
                ROLES.StationOperationManagement,
                ROLES.FSOAdministrativeOfficer,
                ROLES.FSOManager,
              ],
            },
            children: [
              {
                path: "",
                name: "Cointube Main",
                component: () =>
                  import(
                    "../views/cash-management/AFC/Cointube/CointubeMainPage.vue"
                  ),
              },
              {
                path: "use",
                name: "Cointube Use",
                component: () =>
                  import(
                    "../views/cash-management/AFC/Cointube/UseCoinTubes.vue"
                  ),
                meta: {
                  title: "screens.cashManagement.AFC.Cointube.Use.pageTitle",
                  requiresRoles: [
                    ROLES.StationManager,
                    ROLES.SeniorStationManager,
                    ROLES.ServiceOperationManager,
                  ],
                },
              },
            ],
          },
          {
            path: "coinflush",
            component: () => import("../views/DefaultWrapper.vue"),
            meta: {
              title: "screens.cashManagement.AFC.CoinFlush.Main.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
                ROLES.ServiceOperationManager,
                ROLES.ManagerStationOperation,
                ROLES.StationOperationManagement,
                ROLES.FSOAdministrativeOfficer,
                ROLES.FSOManager,
              ],
            },
            children: [
              {
                path: "",
                name: "CoinFlush Main",
                component: () =>
                  import(
                    "../views/cash-management/AFC/CoinFlush/CoinFlushMainPage.vue"
                  ),
                meta: {
                  title: "screens.cashManagement.AFC.CoinFlush.Main.pageTitle",
                },
              },
              {
                path: "create",
                name: "New CoinFlush",
                component: () =>
                  import(
                    "../views/cash-management/AFC/CoinFlush/CoinFlushForm.vue"
                  ),
                meta: {
                  title:
                    "screens.cashManagement.AFC.CoinFlush.Create.pageTitle",
                  requiresRoles: [
                    ROLES.AssistantStationManager,
                    ROLES.SeniorAssistantStationManager,
                    ROLES.StationManager,
                    ROLES.SeniorStationManager,
                  ],
                },
              },
            ],
          },
          {
            path: "sccf",
            component: () => import("../views/DefaultWrapper.vue"),
            meta: {
              title: "screens.cashManagement.AFC.SCCF.Main.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
                ROLES.ServiceOperationManager,
                ROLES.ManagerStationOperation,
                ROLES.StationOperationManagement,
                ROLES.FSOAdministrativeOfficer,
                ROLES.FSOManager,
              ],
            },
            children: [
              {
                path: "",
                name: "SCCF Main",
                component: () =>
                  import("../views/cash-management/AFC/SCCF/SCCFMain.vue"),
              },
              {
                path: "create",
                name: "Create SCCF",
                component: () =>
                  import(
                    "../views/cash-management/AFC/SCCF/Create/CreateSCCF.vue"
                  ),
                meta: {
                  title: "screens.cashManagement.AFC.SCCF.Create.pageTitle",
                  requiresRoles: [
                    ROLES.StationManager,
                    ROLES.SeniorStationManager,
                  ],
                },
              },
              {
                path: "details",
                name: "SCCF Details",
                component: () =>
                  import("../views/cash-management/AFC/SCCF/SCCFDetails.vue"),
                meta: {
                  title: "screens.cashManagement.AFC.SCCF.Details.pageTitle",
                },
              },
            ],
          },
          {
            path: "station-transfer",
            component: () => import("../views/DefaultWrapper.vue"),
            meta: {
              title:
                "screens.cashManagement.AFC.StationTransfer.Main.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
                ROLES.ServiceOperationManager,
                ROLES.ManagerStationOperation,
                ROLES.StationOperationManagement,
                ROLES.FSOAdministrativeOfficer,
                ROLES.FSOManager,
              ],
            },
            children: [
              {
                path: "",
                name: "Station Transfer Main",
                component: () =>
                  import(
                    "../views/cash-management/AFC/StationTransfer/StationTransferMain.vue"
                  ),
              },
              {
                path: "create",
                name: "Create Station Transfer",
                component: () =>
                  import(
                    "../views/cash-management/AFC/StationTransfer/CreateStationTransfer.vue"
                  ),
                meta: {
                  title:
                    "screens.cashManagement.AFC.StationTransfer.Create.pageTitle",
                  requiresRoles: [
                    ROLES.StationManager,
                    ROLES.SeniorStationManager,
                    ROLES.ServiceOperationManager,
                  ],
                },
              },
              {
                path: "details",
                name: "Station Transfer Details",
                component: () =>
                  import(
                    "../views/cash-management/AFC/StationTransfer/StationTransferDetails.vue"
                  ),
                meta: {
                  title:
                    "screens.cashManagement.AFC.StationTransfer.Details.pageTitle",
                },
              },
            ],
          },
          {
            path: "discrepancy-report",
            component: () => import("../views/DefaultWrapper.vue"),
            meta: {
              title:
                "screens.cashManagement.AFC.DiscrepancyReport.CreateDiscrepancyReport.pageTitle",
            },
            children: [
              {
                path: "",
                name: "Create Discrepancy Report AFC",
                component: () =>
                  import(
                    "../views/cash-management/AFC/DiscrepancyReport/CreateDiscrepancyAFC.vue"
                  ),
                meta: {
                  title:
                    "screens.cashManagement.AFC.DiscrepancyReport.CreateDiscrepancyReport.pageTitle",
                  requiresRoles: [
                    ROLES.StationManager,
                    ROLES.SeniorStationManager,
                    ROLES.ServiceOperationManager,
                    ROLES.FSOAdministrativeOfficer,
                    ROLES.FSOManager,
                  ],
                },
              },
            ],
          },
        ],
      },
      {
        path: "cash-order",
        component: () => import("../views/DefaultWrapper.vue"),
        meta: {
          title: "screens.cashManagement.CashOrder.Main.pageTitle",
        },
        children: [
          {
            path: "",
            name: "Cash Order Main Page",
            component: () =>
              import(
                "../views/cash-management/CashOrder/CashOrderMainPage.vue"
              ),
            meta: {
              title: "screens.cashManagement.CashOrder.Main.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
                ROLES.ServiceOperationManager,
                ROLES.ManagerStationOperation,
                ROLES.StationOperationManagement,
                ROLES.FSOAdministrativeOfficer,
                ROLES.FSOManager,
                ROLES.SystemAdmin,
              ],
            },
          },
          {
            path: "new",
            name: "Create Cash Order",
            component: () =>
              import("../views/cash-management/CashOrder/Create.vue"),
            meta: {
              title: "screens.cashManagement.CashOrder.Create.pageTitle",
              requiresRoles: [
                ROLES.StationManager,
                ROLES.SeniorStationManager,
                ROLES.ServiceOperationManager,
              ],
            },
          },
          {
            path: "view/:cashOrderId",
            name: "View Cash Order",
            component: () =>
              import("../views/cash-management/CashOrder/ViewCashOrder.vue"),
            meta: {
              title: "screens.cashManagement.CashOrder.View.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
                ROLES.ServiceOperationManager,
                ROLES.ManagerStationOperation,
                ROLES.StationOperationManagement,
                ROLES.FSOAdministrativeOfficer,
                ROLES.FSOManager,
                ROLES.SystemAdmin,
              ],
            },
          },
          {
            path: "report",
            name: "Cash Order Report",
            component: () =>
              import("../views/cash-management/CashOrder/CashOrderReport.vue"),
            meta: {
              title: "screens.cashManagement.Admin.CashOrderReport.pageTitle",
              requiresRoles: [
                ROLES.StationManager,
                ROLES.SeniorStationManager,
                ROLES.ServiceOperationManager,
                ROLES.ManagerStationOperation,
                ROLES.StationOperationManagement,
                ROLES.FSOAdministrativeOfficer,
                ROLES.FSOManager,
                ROLES.SystemAdmin,
              ],
            },
          },
        ],
      },
      {
        path: "cash-declaration-form",
        component: () => import("../views/DefaultWrapper.vue"),
        meta: {
          title: "screens.cashManagement.CashDeclaration.Main.pageTitle",
        },
        children: [
          {
            path: "",
            name: "Cash Declaration Form List",
            component: () =>
              import(
                "../views/cash-management/CashDeclaration/CashDeclarationFormList.vue"
              ),
            meta: {
              title: "screens.cashManagement.CashDeclaration.Main.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
                ROLES.ServiceOperationManager,
                ROLES.ManagerStationOperation,
                ROLES.StationOperationManagement,
                ROLES.FSOAdministrativeOfficer,
                ROLES.FSOManager,
              ],
            },
          },
          {
            path: "create",
            name: "Cash Declaration Form Create",
            component: () =>
              import(
                "../views/cash-management/CashDeclaration/CashDeclarationFormCreate.vue"
              ),
            meta: {
              title: "screens.cashManagement.CashDeclaration.Create.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
              ],
            },
          },
          {
            path: "view/:cashDeclarationFormId",
            name: "Cash Declaration Form View",
            component: () =>
              import(
                "../views/cash-management/CashDeclaration/CashDeclarationFormView.vue"
              ),
            meta: {
              title: "screens.cashManagement.CashDeclaration.View.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
                ROLES.ServiceOperationManager,
                ROLES.ManagerStationOperation,
                ROLES.StationOperationManagement,
                ROLES.FSOAdministrativeOfficer,
                ROLES.FSOManager,
              ],
            },
          },
          {
            path: "witness-sign/:cashDeclarationFormId",
            name: "Cash Declaration Form Witness Sign",
            component: () =>
              import(
                "../views/cash-management/CashDeclaration/CashDeclarationFormWitnessSign.vue"
              ),
            meta: {
              title:
                "screens.cashManagement.CashDeclaration.WitnessSign.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
              ],
            },
          },
          {
            path: "bagger-sign/:cashDeclarationFormId",
            name: "Cash Declaration Form Bagger Sign",
            component: () =>
              import(
                "../views/cash-management/CashDeclaration/CashDeclarationFormBaggerSign.vue"
              ),
            meta: {
              title:
                "screens.cashManagement.CashDeclaration.BaggerSign.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
              ],
            },
          },
          {
            path: "move-cash-bags",
            name: "Cash Declaration Form Move Cash Bags",
            component: () =>
              import(
                "../views/cash-management/CashDeclaration/MoveCashBags.vue"
              ),
            meta: {
              title:
                "screens.cashManagement.CashDeclaration.MoveCashBags.pageTitle",
              requiresRoles: [
                ROLES.AssistantStationManager,
                ROLES.SeniorAssistantStationManager,
                ROLES.StationManager,
                ROLES.SeniorStationManager,
              ],
            },
          },
        ],
      },

      {
        path: "discrepancy-reports",
        component: () => import("../views/DefaultWrapper.vue"),
        meta: {
          title:
            "screens.cashManagement.Admin.DiscrepancyReports.pageTitle",
          requiresRoles: [
            ROLES.AssistantStationManager,
            ROLES.SeniorAssistantStationManager,
            ROLES.StationManager,
            ROLES.SeniorStationManager,
            ROLES.ServiceOperationManager,
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
            ROLES.FSOAdministrativeOfficer,
            ROLES.FSOManager,
          ],
        },
        children: [
          {
            path: "",
            name: "Discrepancy Reports",
            component: () =>
              import(
                "../views/cash-management/Admin/DiscrepancyReport/DiscrepancyReportMain.vue"
              ),
            meta: {
              title:
                "screens.cashManagement.Admin.DiscrepancyReports.pageTitle",
            },
            requiresRoles: [
              ROLES.AssistantStationManager,
              ROLES.SeniorAssistantStationManager,
              ROLES.StationManager,
              ROLES.SeniorStationManager,
              ROLES.ServiceOperationManager,
              ROLES.ManagerStationOperation,
              ROLES.StationOperationManagement,
              ROLES.FSOAdministrativeOfficer,
              ROLES.FSOManager,
            ],
          },
          {
            path: "update/:id",
            name: "Update Discrepancy Report",
            component: () =>
              import(
                "../views/cash-management/Admin/DiscrepancyReport/UpdateDiscrepancyReport.vue"
              ),
            meta: {
              title:
                "screens.cashManagement.Admin.UpdateDiscrepancyReport.pageTitle",
            },
            requiresRoles: [
              ROLES.ServiceOperationManager,
              ROLES.ManagerStationOperation,
              ROLES.StationOperationManagement,
              ROLES.FSOAdministrativeOfficer,
              ROLES.FSOManager,
            ],

          },
        ],
      },

      // {
      //   path: "collection-summary",
      //   component: () => import("../views/DefaultWrapper.vue"),
      //   meta: {
      //     title:
      //       "screens.cashManagement.Admin.CollectionSummaryMain.pageTitle",
      //   },
      //   children: [
      //     {
      //       path: "",
      //       name: "Cash Collection Summary Main Page",
      //       component: () =>
      //         import(
      //           "../views/cash-management/Admin/CollectionSummary/CollectionSummaryMain.vue"
      //         ),
      //       meta: {
      //         title:
      //           "screens.cashManagement.Admin.CollectionSummaryMain.pageTitle",
      //         requiresRoles: [
      //           ROLES.ManagerStationOperation,
      //           ROLES.StationOperationManagement,
      //           ROLES.FSOAdministrativeOfficer,
      //           ROLES.FSOManager,
      //         ],
      //       },
      //     },
      //     {
      //       path: "report",
      //       name: "Cash Collection Summary Report",
      //       component: () =>
      //         import(
      //           "../views/cash-management/Admin/CollectionSummary/CollectionSummaryReport.vue"
      //         ),
      //       meta: {
      //         title:
      //           "screens.cashManagement.Admin.CollectionSummaryReport.pageTitle",
      //         requiresRoles: [
      //           ROLES.ManagerStationOperation,
      //           ROLES.StationOperationManagement,
      //           ROLES.FSOAdministrativeOfficer,
      //           ROLES.FSOManager,
      //         ],
      //       },
      //     },
      //     {
      //       path: "upload-certis",
      //       name: "Cash Collection Summary Upload Certis Report",
      //       component: () =>
      //         import(
      //           "../views/cash-management/Admin/CollectionSummary/UploadCertisReport.vue"
      //         ),
      //       meta: {
      //         title:
      //           "screens.cashManagement.Admin.CollectionSummaryUploadCertis.pageTitle",
      //         requiresRoles: [
      //           ROLES.FSOAdministrativeOfficer,
      //           ROLES.FSOManager,
      //         ],
      //       },
      //     },
      //     {
      //       path: "upload-sep",
      //       name: "Cash Collection Summary Upload SeP Report",
      //       component: () =>
      //         import(
      //           "../views/cash-management/Admin/CollectionSummary/UploadSePReport.vue"
      //         ),
      //       meta: {
      //         title:
      //           "screens.cashManagement.Admin.CollectionSummaryUploadSeP.pageTitle",
      //         requiresRoles: [
      //           ROLES.FSOAdministrativeOfficer,
      //           ROLES.FSOManager,
      //         ],
      //       },
      //     },
      //   ],
      // },

      {
        path: "configuration",
        name: "Cash Management Configuration",
        component: () =>
          import(
            "../views/cash-management/Admin/Configuration/ConfigurationMain.vue"
          ),
        meta: {
          title: "screens.cashManagement.Admin.Configuration.pageTitle",
          requiresRoles: [
            ROLES.ServiceOperationManager,
            ROLES.ManagerStationOperation,
            ROLES.StationOperationManagement,
            ROLES.FSOAdministrativeOfficer,
            ROLES.FSOManager,
            ROLES.SystemAdmin,
          ],
        },
      },
    ],
  },
];
